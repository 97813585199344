import Mock from "../mock";

const database = {
    information: {
        name: 'Marcelo Duarte Trevisani',
        aboutContent: "Enthusiastic software engineer with passion to build great products. Obsessed in create code/algorithms which humans will understand (not just the machines :D ).",
        nationality: 'Italian / Brazilian - dual citizen',
        language: 'English, Portuguese',
        email: 'contact@marcelotrevisani.info',
        address: 'Cambridge, UK',
        socialLinks: {
            linkedin: 'https://www.linkedin.com/in/marcelodtrevisani/',
            github: 'https://github.com/marcelotrevisani'
        },
        brandImage: '/images/brand-image.jpg',
        aboutImage: '/images/about-image.jpg',
    },
    skills: [
        {
            title: "Python",
            value: 95
        },
        {
            title: "C++",
            value: 75
        },
        {
            title: "Git",
            value: 70
        },
        {
            title: "DevOps",
            value: 85
        },
        {
            title: "Pragmatic Programmer",
            value: 80
        },
        {
            title: "Test Driven Development",
            value: 90
        }
    ],
    experience: {
        workingExperience: [
            {
                id: 1,
                year: "11/2021 - Present",
                position: "Software Engineer - Contractor",
                company: "Gazprom Marketing & Trading",
            },
            {
                id: 2,
                year: "01/2021 - 11/2021",
                position: "Software Engineer - Contractor",
                company: "BT",
            },
            {
                id: 3,
                year: "01/2019 - 03/2021",
                position: "Senior Software Design Engineer",
                company: "Imagination Technologies",
            },
            {
                id: 4,
                year: "12/2019 - 03/2020",
                position: "Software Engineer - Contractor, Small Development Grant",
                company: "NumFOCUS",
            },
            {
                id: 5,
                year: "03/2018 - 09/2018",
                position: "Software Engineer - Contractor",
                company: "European X-Ray Free-Electron Lase (XFEL)",
            },
            {
                id: 6,
                year: "01/2017 - 12-2017",
                position: "Software Engineer",
                company: "Engineering Simulation and Scientific Software (ESSS)"
            },
            {
                id: 7,
                year: "04/2016 - 12-2016",
                position: "Software Engineer - Contractor",
                company: "MSX International"
            },
        ],
        educationExperience: [
            {
                id: 1,
                year: "08/2008 - 12/2015",
                graduation: "Control and Automation Engineering",
                university: "Federal University of Minas Gerais (UFMG - Universidade Federal de Minas Gerais)",
            },
            {
                id: 2,
                year: "02/2016 - 10/2016",
                graduation: "Junior Researcher - Data Science",
                university: "Federal University of Minas Gerais (UFMG - Universidade Federal de Minas Gerais)",
            },
            {
                id: 3,
                year: "06/2014 - 08/2014",
                graduation: "Summer Internship",
                university: "Ostfalia University",
            },
            {
                id: 4,
                year: "07/2010 - 11/2013",
                university: "Federal University of Minas Gerais (UFMG - Universidade Federal de Minas Gerais)",
                graduation: "Junior Researcher - Software Development",
            }
        ]
    },
    blogs: [
        {
            id: 1,
            title: 'Grayskull - By the power of Grayskull… I have the Conda recipe!',
            featuredImage: '/images/grayskull.jpeg',
            filesource: '../../blog/grayskull-blog.md',
            createDay: "05",
            createMonth: 'March',
            createYear: "2020"
        },

    ],
    contactInfo: {
        emailAddress: ['contact@marcelotrevisani.com', 'contact@marcelotrevisani.info'],
        address: "Cambridge, United Kingdom"
    }
}


Mock.onGet("/api/information").reply(config => {
    const response = database.information;
    return [200, response];
});

Mock.onGet("/api/services").reply(config => {
    const response = database.services;
    return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
    const response = database.reviews;
    return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
    const response = database.skills;
    return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
    const response = database.experience;
    return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
    const response = database.blogs;
    return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
    const response = database.contactInfo;
    return [200, response];
});
