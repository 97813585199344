import React, {useState, useEffect} from "react";
import axios from 'axios';
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";

function About() {
    const [information, setInformation] = useState("");

    useEffect(() => {
        axios.get('/api/information')
            .then(response => {
                setInformation(response.data)
            })
    }, [])

    return (
        <Layout>
            <div className="mi-about-area mi-section mi-padding-top">
                <div className="container">
                    <Sectiontitle title="About Me"/>
                    <div className="row">

                        <div className="mi-about-content">
                            <h3>
                                I am <span
                                className="color-theme">{information.name}</span>
                            </h3>
                            <p>
                                Enthusiastic developer with passion to build
                                great products. Obsessed in create code /
                                algorithms which humans will understand (not
                                just the machines :D ) and always thinking how
                                to improve the performance of the software.
                                Passionate for some areas such as software
                                development, DevOps, Agile Methodologies and
                                embedded systems.
                                <br/><br/>
                                I try to be a Pragmatic Agile Developer;
                                Pragmatic based on the characteristics described
                                in the book "The Pragmatic Programmer" and Agile
                                based in the Agile Manifesto and the book
                                "Extreme Programming".
                                <br/><br/>
                                In my free time, I try to help some opensource
                                projects such as conda-forge community (I'm one
                                of the core members) and I'm
                                one of the maintainers of Conda build and
                                Grayskull.
                                <br/><br/>
                                Interests in Python, Rust, C++, Julia Language,
                                DevOps, Agile Methodologies, OpenSource
                                community, and software development area in
                                general.
                                <br/><br/>
                                Italian/Brazilian (I'm a dual citizen) living and working in the United
                                Kingdom.
                            </p>
                            <ul>
                                {!information.name ? null : <li>
                                    <b>Full Name</b> {information.name}
                                </li>}
                                {!information.age ? null : <li>
                                    <b>Age</b> {information.age} Years
                                </li>}
                                {!information.phone ? null : <li>
                                    <b>Phone</b> {information.phone}
                                </li>}
                                {!information.nationality ? null : <li>
                                    <b>Nationality</b> {information.nationality}
                                </li>}
                                {!information.language ? null : <li>
                                    <b>Languages</b> {information.language}
                                </li>}
                                {!information.email ? null : <li>
                                    <b>Email</b> {information.email}
                                </li>}
                                {!information.address ? null : <li>
                                    <b>Address</b> {information.address}
                                </li>}
                                {!information.freelanceStatus ? null : <li>
                                    <b>Freelance</b> {information.freelanceStatus}
                                </li>}
                                <li>
                                    <b>GitHub</b> <a href="https://github.com/marcelotrevisani">github.com/marcelotrevisani</a>
                                </li>
                                <li>
                                    <b>Linkedin</b> <a href="https://www.linkedin.com/in/marcelodtrevisani/">linkedin.com/in/marcelodtrevisani</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default About;
