import React, {Component} from "react";
import ReactLoading from "react-loading";

import {CopyBlock, vs2015} from 'react-code-blocks'


class RecipeView extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            currentRecipe: "",
            name: props.name,
            version: props.version,
            error: ""
        }
    }

    async componentDidMount() {
        let url_fetch = 'https://marcelotrevisani.info:8000/recipe/?name=' + this.state.name;
        if (this.state.version !== "") {
            url_fetch += '&version=' + this.state.version;
        }
        try {
            await fetch(url_fetch, {method: 'GET'})
                .then(response => {
                    if (response.ok)
                        return response.text()
                    else
                        throw new Error(
                            "It was not possible to generate the recipe. "
                            + response.statusText
                            + ". Please check the package name and version if"
                            + " they are available on PyPI."
                        )
                })
                .then(
                    data => this.setState({
                        currentRecipe: data,
                        loading: false,
                        error: ""
                    })
                );
        } catch (err) {
            this.setState({
                currentRecipe: "",
                loading: false,
                error: err.message
            })
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.loading ?
                        <div>
                            <h1>Generating Recipe...</h1>
                            <ReactLoading type={"bars"} color={"white"}
                                          height={'20%'}
                                          width={'20%'}/>
                        </div>
                        :
                        this.state.error === "" ?
                            <div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: 'space-between'
                                }}>
                                    <h3>Recipe generate
                                        for {this.state.name}</h3>
                                    <button className="mi-button"
                                            type="submit"
                                            style={{align: "right"}}
                                            onClick={() => window.location.href = '/grayskull'}>Reset
                                    </button>
                                </div>

                                <CopyBlock language="yaml"
                                           text={this.state.currentRecipe}
                                           theme={vs2015}/>
                            </div> :
                            <div>
                                <h3>Error - {this.state.name}</h3>
                                <h4>{this.state.error}</h4>
                                <button className="mi-button"
                                        type="submit"
                                        style={{align: "right"}}
                                        onClick={() => window.location.href = '/grayskull'}>Reset
                                </button>
                            </div>
                }
            </div>
        )
    }
}

export default RecipeView;
