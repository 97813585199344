import React, {useState, useEffect} from "react";
import axios from 'axios';
import * as Icon from "react-feather";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";

function Contact() {
    const [emailAddress, setEmailAddress] = useState([]);
    const [address, setAddress] = useState([]);

    useEffect(() => {
        axios.get('/api/contactinfo')
            .then(response => {
                setEmailAddress(response.data.emailAddress);
                setAddress(response.data.address);
            })
    }, [])

    return (
        <Layout>
            <div
                className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <Sectiontitle title="Contact Me"/>
                    <div className="row">

                        <div className="col-lg-6">
                            <div className="mi-contact-info">
                                {!emailAddress ? null : (
                                    <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.Mail/>
                    </span>
                                        <div
                                            className="mi-contact-infoblock-content">
                                            <h6>Email</h6>
                                            {emailAddress.map(email => (
                                                <p key={email}><a
                                                    href={`mailto:${email}`}>{email}</a>
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.MapPin/>
                    </span>
                                    <div
                                        className="mi-contact-infoblock-content">
                                        <h6>Address</h6>
                                        <p>{address}</p>
                                    </div>
                                </div>

                                <a rel="noopener noreferrer" target="_blank"
                                   href="https://www.linkedin.com/in/marcelodtrevisani/">
                                    <div className="mi-contact-infoblock">

                    <span className="mi-contact-infoblock-icon">
                        <Icon.Linkedin/>
                    </span>

                                        <div
                                            className="mi-contact-infoblock-content">
                                            <h6>Linkedin</h6>
                                            <p>linkedin.com/in/marcelodtrevisani/</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Contact;
