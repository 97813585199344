import React, {Component} from "react";
import Layout from "../components/Layout";
import RecipeView from "./RecipeView";
import Sectiontitle from "../components/Sectiontitle";


class GrayskullView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showRecipe: false,
            name: "",
            version: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    generateRecipeHandler = () => {
        this.setState({showRecipe: true})
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }

    render() {
        return (
            <Layout>
                <div className="container">
                    <Sectiontitle title="Grayskull - Conda Recipe Generator"/>
                    {
                        this.state.showRecipe === false ?
                            <div>
                                Grayskull is a project to generate recipes for
                                conda. For
                                now it just supports projects which are on pypi.<br/>
                                <h6><a href="/blogs/blog-details/1/grayskull-blog">For more information please click here to see the blog post.</a></h6>
                                <div className="row" style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex"
                                }}>
                                    <div className="mi-form-field">
                                        <label htmlFor="contact-form-name">Enter
                                            project name*</label>
                                        <input type="text"
                                               name="name"
                                               id="contact-form-name"
                                               value={this.state.name}
                                               onChange={(event) => {
                                                   this.setState({name: event.target.value})
                                               }}
                                        />
                                    </div>

                                    <div className="mi-form-field">
                                        <label
                                            htmlFor="contact-form-name">Version</label>
                                        <input type="text"
                                               name="version"
                                               id="contact-form-version"
                                               value={this.state.version}
                                               onChange={(event) => {
                                                   this.setState({version: event.target.value})
                                               }}
                                        />
                                    </div>

                                    <div className="mi-form-field">
                                        <button className="mi-button"
                                                type="submit"
                                                onClick={this.generateRecipeHandler}>Generate
                                            Recipe
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div
                                className="mi-about-area mi-section mi-padding-top">
                                <div className="container"
                                     style={{
                                         alignItems: "center",
                                         justifyContent: "center",
                                         display: "flex"
                                     }}>
                                    <RecipeView name={this.state.name}
                                                version={this.state.version}/>
                                </div>
                            </div>}
                </div>
            </Layout>
        );
    }
}

export default GrayskullView;
